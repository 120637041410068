import { invert } from 'lodash';
import { Moment } from 'moment';

type State = {
  distance: {
    name: string;
    description: string;
    helper_text: string;
    // Registration enabled
    registration_starts_at: string | undefined;
    registration_ends_at: string | undefined;
    // Logging enabled
    race_date: string | undefined;
    ends_at?: string;
    type: string;
    start_type: string;
    max_members_quantity: nil | number;
    min_members_quantity: nil | number;
    team_relay: boolean;
    is_for_kids: number;
    allow_no_profile_registration: unknown;
    price: nil | number;
    race_length: nil | unknown;
    race_qty: nil | unknown;
    race_time: string | undefined;
    mark_racers_come: number;
    show_grayed_out_racers: number;
    allow_invite: number;
    location: string;
    id?: number;
    is_visible: boolean;
    vat_percents: string;
    refund_protect_enabled: boolean;
    distance_mode?: number;
    email_content?: {
      content?: string;
    };
    registration_range?: {
      registration_starts_at?: Moment;
      registration_ends_at?: Moment;
    };
    is_edit_registration_available: boolean;
    edit_registration_settings: {
      from: string;
      to: string;
      id?: number;
    };
    is_transfer_registration_available: boolean;
    isTransferRegistrationEdit: boolean;
    isEditRegistrationEdit: boolean;

    transfer_registration_settings: {
      from: string;
      to: string;
      price: string;
    };
    racers_count: number;
    raceband_used: boolean;
  };
  step: {
    value: string;
    index: number;
  };
  confirmPopup: nil | Function;
  registrationFields: Array<RegistrationField>;
  editorSettings: EditorSettings;
  checkpoints: Array<CheckpointType>;
  classes: Array<ClassType>;
  prices: Array<PriceType>;
  disciplines: Array<DisciplineType>;
  medical_assistants: Array<MedicalAssistantType>;
  timing_assistants: Array<TimingAssistantType>;
  waves: Array<WaveType>;
  custom_fields: Array<CustomFieldType>;
  email_content?: string;
  goal?: {
    goal_type: number | null;
    goal_max_length?: string | nil;
  };
  isDirty: boolean;
};

const stateRelations: AnyObject = {
  registrationFields: 'registration_fields',
  editorSettings: 'editor_settings',
  checkpoints: 'checkpoints',
  classes: 'classes',
  disciplines: 'disciplines',
  prices: 'prices',
  waves: 'waves',
  custom_fields: 'custom_fields',
  medical_assistants: 'medical_assistants',
  timing_assistants: 'timing_assistants',
  goal: 'goal',
};

const stepRelations: AnyObject = {
  registrationFields: 'registrationFields',
  tabOptions: 'editorSettings',
  baseStep: 'distance',
  checkpoints: 'checkpoints',
  classes: 'classes',
  disciplines: 'disciplines',
  prices: 'prices',
  waves: 'waves',
  custom_fields: 'custom_fields',
  medical_assistants: 'medical_assistants',
  timing_assistants: 'timing_assistants',
};

const relationSteps = invert(stepRelations);

const deleteRelation = (relation: Array<Object>) => {
  if (!relation) {
    return [];
  }

  return relation.reduce((acc: AnyObject | any, item: AnyObject | any) => {
    if (item.id) {
      return [...acc, { ...item, _delete: true }];
    } else {
      return acc;
    }
  }, []);
};

export { stateRelations, deleteRelation, stepRelations, relationSteps, State };
